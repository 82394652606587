import React from "react";
import isNil from "lodash/isNil";

import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import { useAuth } from "@api/auth";
import { ProductType } from "@api/products";

import CompanyPanel from "~/components/CompanyPanel";
import { Typography } from "~/components/Typography";

import { ViewComponent, createPrivateView } from "~/model/view/private";

import { OffersDisplay } from "./_lib/OffersDisplay";

const useStyles = makeStyles({
    content: {
        position: "relative",
        display: "flex",
        flexFlow: "row nowrap",
        height: "100%",
    },
    userContent: {
        width: "100%",
        minHeight: "100%",
        overflow: "hidden",
        display: "flex",
        flexFlow: "column nowrap",
        position: "relative",
    },
    error: {
        paddingTop: 132,
        display: "flex",
        flexFlow: "column nowrap",
        justifyContent: "center",
        alignItems: "center",
    },
});

export const Offers: ViewComponent = () => {
    const { user, selectedCompany } = useAuth();
    const styles = useStyles();

    const hasOffers = user?.hasProduct(ProductType.TradePro, selectedCompany?.id);

    return (
        <Box className={styles.content}>
            {(user?.companies || []).length > 1 && (
                <CompanyPanel
                    subtitle={(item) => (
                        isNil(item?.userCount)
                            ? ""
                            : `${item?.userCount} users`
                    )}
                    placement="inline"
                />
            )}
            <Box className={styles.userContent}>
                {hasOffers ? (
                    <OffersDisplay />
                ) : (
                    <Box className={styles.error}>
                        <Typography variant="title">
                            {"Oops!  Something went wrong"}
                        </Typography>
                        {(user?.companies || []).length > 1 ? (
                            <Typography paragraph>
                                {"The Dealership you have selected cannot view Offers"}
                            </Typography>
                        ) : (
                            <Typography paragraph>
                                {"Your Dealership cannot view Offers"}
                            </Typography>
                        )}
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export default createPrivateView(Offers, {
    title: "Offers",
    internalScroll: true,
    internalPad: true,
});