import React, {
    PropsWithChildren,
    ElementType,
    ComponentProps,
    Fragment,
    useMemo,
    useState,
    useCallback,
} from "react";
import isNil from "lodash/isNil";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import clsx from "clsx";

import { Typography } from "~/components/Typography";
import { InternalPanel } from "@layouts/Dashboard/InternalPanel";
import { avatars } from "./avatars";
import { useAuth } from "@api/auth";

import type { ICompanyPanelProps, IWrapperMeta } from "./types";

const padding = "12px 20px";
const useStyles = makeStyles((theme) => {
    const border = `1px solid ${theme.palette.borderInternal.main}`;
    return {
        container: {
            borderRight: border,
            minHeight: "100%",
            userSelect: "none",
            overflow: "auto",
            width: "20%",
            maxWidth: 325,
            minWidth: 275,
        },
        containerTitle: {
            padding,
            top: 0,
            backgroundColor: theme.palette.background.default,
            position: "sticky",
        },

        listItem: {
            padding,
            borderBottom: border,
            display: "flex",
            flexFlow: "row nowrap",
            "&.selected": {
                backgroundColor: theme.palette.background.paper,
            },
            "&:hover": {
                cursor: "pointer",
                backgroundColor: theme.palette.action.hover,
            },
        },
        logoContainer: {
            minWidth: 40,
            width: 40,
            height: 40,
            marginRight: 8,
            borderRadius: 5,
            overflow: "hidden",
            // backgroundColor: theme.palette.background.default,
        },
        listContent: {
            display: "flex",
            flexFlow: "column nowrap",
        },
    };
});

const getWrapper = <T extends ElementType>(
    element: T,
    props: ComponentProps<T>,
): IWrapperMeta<T> => ({
    Wrapper: element,
    props,
});

const CompanyPanel = ({
    collection: collectionInput,
    id = (company) => company?.id.toString() || "",
    title = (company) => company?.name || "!!UNKNOWN COMPANY!!",
    subtitle = () => "",
    selected,
    onSelect,
    placement = "layout",
    className = "",
}: PropsWithChildren<ICompanyPanelProps>): JSX.Element => {
    const { selectedCompany, changeCompany, user } = useAuth();
    const styles = useStyles();
    const [titleDepth, setTitleDepth] = useState(0);
    const {
        Wrapper,
        props: wrapperProps,
    } = useMemo(() => {
        switch (placement) {
            case "layout": return getWrapper(
                InternalPanel,
                {},
            );
            case "inline": return getWrapper(
                Fragment,
                {},
            );
        }
    }, [placement]);

    const collection = useMemo(() => (
        isNil(collectionInput)
            ? user?.companies || []
            : collectionInput
    ), [user, collectionInput]);

    const isSelected = useCallback<Required<ICompanyPanelProps>["selected"]>((company) => (
        !isNil(selected)
            ? selected(company)
            : !isNil(selectedCompany) && !isNil(company)
                ? company.id === selectedCompany.id
                : false
    ), [selected, selectedCompany]);

    const doChangeCompany = useCallback<Required<ICompanyPanelProps>["onSelect"]>((company) => (
        !isNil(onSelect)
            ? onSelect(company)
            : changeCompany(company.id)
    ), [onSelect, changeCompany]);

    const handleContainerRef = useCallback((el: HTMLDivElement | null) => {
        if (el) {
            el.addEventListener("scroll", () => {
                if (el.scrollTop === 0) {
                    setTitleDepth(0);
                } else {
                    setTitleDepth(3);
                }
            });
        }
    }, []);

    return (
        <Wrapper {...wrapperProps}>
            <div className={clsx(styles.container, className)} ref={handleContainerRef}>
                <Box className={styles.containerTitle} boxShadow={titleDepth}>
                    <Typography variant="groupTitle">
                        {"Dealerships"}
                    </Typography>
                </Box>
                {collection.map((item, i) => {
                    if (!item) return null;

                    const itemId = id(item) || i;
                    const itemTitle = title(item);
                    const itemSubtitle = subtitle(item);
                    const itemSelected = isSelected(item);

                    return (
                        <div
                            key={`company-list-${itemTitle}-${itemId}`}
                            className={clsx(
                                styles.listItem,
                                itemSelected && "selected",
                            )}
                            onClick={() => { doChangeCompany(item); }}
                        >
                            <div className={styles.logoContainer}>
                                <img src={`/assets/img/${avatars[i % 20]}`} />
                            </div>
                            <div className={styles.listContent}>
                                <Typography variant="inputLabel">
                                    {itemTitle}
                                </Typography>
                                <Typography
                                    variant="body1"
                                    style={{
                                        minHeight: "1.45em",
                                        fontSize: 14,
                                        lineHeight: "14px",
                                    }}
                                >
                                    {itemSubtitle}
                                </Typography>
                            </div>
                        </div>
                    );
                })}
            </div>
        </Wrapper>
    );
};

export default CompanyPanel;