export const avatars = [
    "1_bmw_i8.jpg",
    "2_corolla_1987.jpg",
    "3_maserati.jpg",
    "4_supra_2005.jpg",
    "5_corvette_stingray.jpg",
    "6_datsun_240z_1972.jpg",
    "7_big_foot.jpg",
    "8_charger_2019.jpg",
    "9_f150.jpg",
    "10_herbie.jpg",
    "11_mclaren_f1.jpg",
    "12_rolls_royce_1952.jpg",
    "13_jeep_wrangler.jpg",
    "14_f1_ferrari.jpg",
    "15_mustang_1969.jpg",
    "16_nissan_skyline.jpg",
    "17_porsche_911_1967.jpg",
    "18_tesla_3.jpg",
    "19_tesla_cybertruck.jpg",
    "20_trans_am.jpg",
];